import isEmpty from "lodash/isEmpty";

import * as zod from "zod";
import {
  AddressAnswer,
  ContactAnswer,
  PAGE_QUIZ_CTA_BUTTON_INFO,
  QuizAddressAdditionalFieldsResponse,
  QuizQuestion,
  QuizQuestionAnswer,
  QuizQuestionConditionOperator,
  QuizQuestionInputProps,
  QuizQuestionInputType,
  QuizQuestionVisibleFields
} from "components/Quiz/quizTypes";
import { RecordItem } from "types/common";
import { USER_TYPE } from "utils/constants";

export const QUESTION_TYPES = {
  standard: "standard",
  address: "address",
  account: "account",
  input: "input"
};

export const isNextDisabled = (question?: QuizQuestion, answer?: QuizQuestionAnswer) => {
  if (!question?.type) return true;
  if (
    question.type === QUESTION_TYPES.address &&
    question.additionalAddressInfoFields?.enabled &&
    !formatAddress(answer as AddressAnswer)
  ) {
    return true;
  }
  if (
    question.type === QUESTION_TYPES.input &&
    question.inputType === QuizQuestionInputType.Slider &&
    question.isRequired
  ) {
    return question.answer === undefined || question.answer === null;
  }

  if (question.isRequired) {
    return isEmpty(answer);
  }
  return false;
};

export enum QUIZ_TYPES {
  "sell-property" = "sell-property",
  "sell-property-short" = "sell-property-short",
  "fast-cash-offer" = "fast-cash-offer",
  "oferta-rapida-en-efectivo" = "oferta-rapida-en-efectivo",
  "test-quiz" = "test-quiz",
  "space-quiz" = "space-quiz",
  "investor-profile" = "investor-profile",
  "realtor-partnership" = "realtor-partnership",
  "wholesaler-partnership" = "wholesaler-partnership",
  "subject-to-deal" = "subject-to-deal",
  "owner-financing-deal" = "owner-financing-deal",
  "renovation-profit-sharing-deal" = "renovation-profit-sharing-deal"
}

export enum QUIZ_EVENT {
  QUIZ_ANSWER_SUBMITTED = "quiz_answer_submitted",
  QUIZ_STARTED = "quiz_started",
  QUIZ_COMPLETED = "quiz_completed",
  QUIZ_STYLE_IMAGES_SUBMITTED = "quiz_style_images_submitted"
}

export const formatAddress = (address: RecordItem) => {
  if (!address?.addressLine1) return "";

  return `${address.addressLine1}, ${address.city}, ${address.state}`;
};

export const formatAddressWithZipCode = (address: RecordItem) => {
  if (!address?.addressLine1) return "";

  return `${address.addressLine1}, ${address.city}, ${address.state} ${address.zipCode}`;
};

export const addressValidationSchema = zod.object({
  addressLine1: zod.string().min(1, {
    message: "Address is required"
  }),
  city: zod.string().min(1, {
    message: "City is required"
  }),
  state: zod.string().min(1, {
    message: "State is required"
  }),
  zipCode: zod
    .string()
    .min(1, {
      message: "Zip code is required"
    })
    .regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
      message: "Invalid zip code"
    })
});

export const capitalizeName = (str = "") => {
  return str
    .split(" ")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");
};

export const getAdjustedPosition = (currentPosition: string) => {
  return +currentPosition;
};

export const getQuizBody = ({
  questions,
  isHouzzLead,
  project
}: {
  questions: QuizQuestion[];
  isHouzzLead?: boolean;
  project?: RecordItem;
}) => {
  let quizBody: RecordItem[] = [];
  let clientDetails: RecordItem = {};
  let deal: RecordItem = {};
  const houzzLeadFields = isHouzzLead ? { projectId: project?.id } : {};
  let webhookURL = "";
  let redirectLink: QuizQuestion["redirectLink"];
  let clientUserType: USER_TYPE | undefined;
  let isQuizValid = true;
  questions.forEach((questionItem) => {
    const projectType =
      questionItem.questionFormat === "text"
        ? questionItem?.options
            ?.find((questionAnswer) => {
              return (questionItem.answer as string[])?.[0] === questionAnswer.primaryText;
            })
            ?.metaTags?.find((tag) => tag?.name === "projectType")
        : null;

    if (projectType) {
      deal.type = projectType.value;
    }

    if (questionItem.webhookURL) {
      webhookURL = questionItem.webhookURL;
    }

    if (questionItem.redirectLink) {
      redirectLink = questionItem.redirectLink;
    }

    if (questionItem.type === QUESTION_TYPES.standard && checkQuizQuestionValid(questions, questionItem)) {
      quizBody = [
        ...quizBody,
        {
          question: {
            id: questionItem.id,
            title: questionItem.title,
            subtitle: questionItem.subtitle,
            type: questionItem.type,
            options: questionItem.options
          },
          questionFormat: questionItem.questionFormat,
          singleAnswer:
            questionItem.questionFormat === "text"
              ? questionItem.options?.find((opt) => opt.primaryText === (questionItem.answer as string[])?.[0])
              : undefined,
          optionsAnswer:
            questionItem.questionFormat === "options"
              ? questionItem.options?.filter((opt) => (questionItem.answer as string[])?.includes(opt.primaryText))
              : undefined,
          ...houzzLeadFields
        }
      ];
      if (questionItem.isRequired && isEmpty(questionItem.answer)) {
        isQuizValid = false;
      }
    }

    if (questionItem.type === QUESTION_TYPES.input && checkQuizQuestionValid(questions, questionItem)) {
      quizBody = [
        ...quizBody,
        {
          question: {
            id: questionItem.id,
            title: questionItem.title,
            subtitle: questionItem.subtitle,
            type: questionItem.type,
            inputType: questionItem.inputType
          },
          questionFormat: questionItem.questionFormat,
          answer: questionItem.answer,
          skipTextClicked: questionItem.skipTextClicked,
          ...houzzLeadFields
        }
      ];
    }

    if (questionItem.type === QUESTION_TYPES.address) {
      const answer = questionItem.answer as AddressAnswer;
      if (!isEmpty(answer)) {
        deal = {
          ...deal,
          addressLine1: answer.addressLine1,
          city: answer.city,
          state: answer.state,
          zipCode: answer.zipCode,
          latitude: `${answer.latitude || ""}`,
          longitude: `${answer.longitude || ""}`,
          additionalInfoFields: questionItem.additionalAddressInfoFields?.fields ?? undefined
        };
      }
      if (
        questionItem.isRequired &&
        (isEmpty(answer.addressLine1) || isEmpty(answer.state) || isEmpty(answer.zipCode))
      ) {
        isQuizValid = false;
      }
    }

    if (questionItem.type === QUESTION_TYPES.account) {
      const { finalPhoneNumber, ...rest } = questionItem.answer as ContactAnswer;
      clientDetails = {
        ...rest,
        phone: finalPhoneNumber && finalPhoneNumber.startsWith("+1") ? finalPhoneNumber.replace("+1", "") : rest.phone
      };
      clientUserType = questionItem.userType;
      if (questionItem.isRequired && isEmpty(rest.email)) {
        isQuizValid = false;
      }
    }
  });
  return {
    quizBody,
    clientDetails,
    deal,
    webhookURL,
    redirectLink,
    clientUserType,
    isQuizValid
  };
};

export const PAGE_QUIZ_CTA_BUTTON_MAP: {
  [key: string]: PAGE_QUIZ_CTA_BUTTON_INFO;
} = {
  "/investors": {
    ctaText: "Explore Investment Deals",
    slug: "investor-profile"
  },
  "/realtors": {
    ctaText: "Explore Partnership",
    slug: "realtor-partnership"
  },
  "/wholesalers": {
    ctaText: "Explore Partnership",
    slug: "wholesaler-partnership"
  },
  "/offer-structures/subject-to": {
    ctaText: "Explore Subject-To",
    slug: "subject-to-deal"
  },
  "/offer-structures/owner-financing": {
    ctaText: "Explore Owner Financing",
    slug: "owner-financing-deal"
  },
  "/offer-structures/renovation-profit-sharing": {
    ctaText: "Explore Renovation Profit Sharing",
    slug: "renovation-profit-sharing-deal"
  }
};

export const PAGE_QUIZ_DEFAULT_CTA_BUTTON: PAGE_QUIZ_CTA_BUTTON_INFO = {
  ctaText: "Get a Cash Offer",
  slug: "sell-property"
};

export const getAddressAdditionalFieldsFromApi = async (
  apiUrl: string,
  address?: string
): Promise<QuizAddressAdditionalFieldsResponse> => {
  if (!address) {
    return { data: null, error: null };
  }
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        full_address: address
      })
    });
    if (!response.ok) {
      throw `Server error: [${response.status}] [${response.statusText}] [${response.url}]`;
    }

    const data = await response.json();
    return { data, error: null };
  } catch (err) {
    return { data: null, error: "Failed to fetch address additionalFields from api" };
  }
};

export const getQuizInputValidationSchema = (type?: QuizQuestionInputType, props?: QuizQuestionInputProps) => {
  if (!type) {
    return zod.object({
      inputField: zod.string().min(1)
    });
  }

  switch (type) {
    case QuizQuestionInputType.LongText:
      return zod.object({
        inputField: zod.string().min(1, {
          message: "Field is required"
        })
      });
    case QuizQuestionInputType.Slider:
      return zod.object({
        inputField: zod
          .number()
          .int()
          .min(props?.min ?? 0, {
            message: "Field is required"
          })
          .max(props?.max ?? 100, {
            message: "Field is required"
          })
      });
    default:
      return zod.object({
        inputField: zod.string().min(1, {
          message: "Field is required"
        })
      });
  }
};

export const checkQuizQuestionValid = (questions: QuizQuestion[], questionToValidate: QuizQuestion) => {
  if (!questionToValidate.conditionalLogic) return true;
  const dependsOnQuestion = questions.find((ques) => ques.id === questionToValidate.conditionalLogic?.dependsOn);
  const dependsOnQuestionAnswer = dependsOnQuestion?.answer;
  if (questionToValidate.conditionalLogic?.condition === QuizQuestionConditionOperator.Equals) {
    if (
      Array.isArray(dependsOnQuestionAnswer)
        ? dependsOnQuestionAnswer?.includes(questionToValidate.conditionalLogic?.value as string)
        : dependsOnQuestionAnswer === questionToValidate.conditionalLogic?.value
    ) {
      return true;
    } else {
      return false;
    }
  }

  if (questionToValidate.conditionalLogic?.condition === QuizQuestionConditionOperator.In) {
    if (Array.isArray(dependsOnQuestionAnswer)) {
      return dependsOnQuestionAnswer?.some((ans) =>
        (questionToValidate.conditionalLogic?.value as string[])?.includes(ans)
      );
    } else {
      return (questionToValidate.conditionalLogic?.value as string[])?.includes(dependsOnQuestionAnswer as string);
    }
  }
  return false;
};

export const QUIZ_ACCOUNT_DEFAULT_STEPS = [
  "Get a competitive offer",
  "Fast closing process",
  "Expert guidance every step"
];

export const QUIZ_ACCOUNT_DEFAULT_VISIBLE_FIELDS = {
  name: true,
  email: true,
  phone: true,
  company: false
};

export const getQuizAccountVisibleFieldsValidationSchema = (visibleFields: QuizQuestionVisibleFields) => {
  const schemaObject: RecordItem = {};
  if (visibleFields.name) {
    (schemaObject.firstName = zod
      .string({
        required_error: "First name is required"
      })
      .min(1, {
        message: "First name is required"
      })
      .transform(capitalizeName)),
      (schemaObject.lastName = zod
        .string({
          required_error: "Last name is required"
        })
        .min(1, {
          message: "Last name is required"
        })
        .transform(capitalizeName));
  }

  if (visibleFields.email) {
    schemaObject.email = zod
      .string({
        required_error: "Email is required"
      })
      .min(1, {
        message: "Email is required"
      })
      .email({
        message: "Invalid email address"
      });
  }

  if (visibleFields.company) {
    schemaObject.company = zod
      .string({
        required_error: "Company Name is required"
      })
      .min(1, {
        message: "Company Name is required"
      })
      .transform(capitalizeName);
  }

  if (visibleFields.phone) {
    schemaObject.phone = zod
      .string({
        required_error: "Phone number is required"
      })
      .min(1, {
        message: "Phone number is required"
      })
      .regex(/\+1[0-9]{10}$/, {
        message: "Invalid phone number"
      });
  }

  return zod.object(schemaObject);
};
